import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/parts/Layout";
import { BlogPostList } from "../components/parts/BlogPostList";
import { snProps } from "../js/utils";

import * as blogStyles from "../components/global-styles/blog.module.css";

const YearPage = ({ pageContext, data }) => {
  const { year } = pageContext;
  const { edges } = data.allMarkdownRemark;
  const title = `Posts from ${year}`;

  return (
    <Layout
      title={title}
      description={title}
      location={{ pathname: year }}
      isBlog={true}
      {...snProps}
    >
      <h1 className={`title is-3 ${blogStyles.blogTitle}`}>
        The Clairnote Blog
      </h1>
      <h2 className={`title is-6 ${blogStyles.archivePageTitle}`}>{title}</h2>
      <BlogPostList edges={edges} />
    </Layout>
  );
};

YearPage.propTypes = {
  pageContext: PropTypes.shape({
    yearRegex: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              date: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              path: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export default YearPage;

export const pageQuery = graphql`
  query ($yearRegex: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { date: { regex: $yearRegex }, draft: { ne: true } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            date
            author
            special_byline
            tags
          }
          fields {
            path
          }
          excerpt
        }
      }
    }
  }
`;
